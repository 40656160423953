<template>
    <div class="box">
        <div class="box_container">
            <div style="height:10px;"></div>
            <!-- 下拉框 -->
            <div class="head">
                <div></div>
                <div class="flex">
                    <div class="flex head_dropDown">
                        <span>发送人</span>
                        <el-select class="head_select" v-model="userId" filterable placeholder="请选择发送人">
                            <el-option v-for="item in user" :key="item.User_ID" :label="item.User_Name"
                                :value="item.User_ID">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex head_dropDown">
                        <span>视频类型</span>
                        <el-select class="head_select" v-model="radiobroadcastType" filterable placeholder="请选择视频类型">
                            <el-option v-for="item in radiobroadcastData" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex head_dropDown">
                        <span>日期</span>
                        <el-date-picker v-model="valueStart" type="datetimerange"
                         :picker-options="pickerOptions" range-separator="~" start-placeholder="开始范围" end-placeholder="结束范围" align="right">
                        </el-date-picker>
                    </div>
                    <div class="head_search head_dropDown mouse" @click="search()">
                        搜索
                    </div>
                    <div class="head_but mouse" @click="dowloan()">
                        批量下載
                    </div>
                </div>
            </div>
            <!-- 下拉结束 -->
            <!-- 表格 -->
            <div class="table table_one">
                <history-table :bool="bool" :tableData="tableData" :tableHead="tableHead" ref="history"
                    @getSelectiondata='getSelectiondata'>
                    <el-table-column slot="operation" label="操作" width="200">
                        <template slot-scope="scope">
                            <div class="table_box">
                                <span class="table_but mouse" style="margin-right:0px;"
                                    @click="playbackVideo(scope.row)">回放</span>
                                <span style="color: #67c5cd;"> / </span>
                                <span class="table_but mouse" @click="download(scope.row)">下载</span>
                            </div>
                        </template>
                    </el-table-column>
                </history-table>
            </div>
            <!-- 表格结束 -->
            <!-- 分页 -->
            <div class="pagination">
                <el-pagination class="pagination_bottom" :current-page="paging.pageNo" background
                    :page-sizes="[20, 40, 60, 80]" layout="total,prev, pager, next,jumper,slot, sizes"
                    @size-change="sizeChange" @prev-click="prevClick" @next-click="nextClick"
                    @current-change="currentChange" :total="paging.total">
                    <span class="confirbtn" @click="comfir()">确定</span>
                </el-pagination>
            </div>
        </div>
        <el-dialog title="视频回放" :visible.sync="videoData.dialogVisible" :append-to-body="true" :width="clientWidth+10+'px'" :before-close="handleClose">
            <!-- style="object-fit: fill;" -->
            <video ref="myVideo" controls="controls" 
                :autoplay="true" :src="videoData.url" class="video-box" preload='auto' :style="'width:' + clientWidth + 'px;' + 'height:' + clientHeight + 'px;'" ></video>
            <!-- <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                :options="playerOptions"></video-player> -->
        </el-dialog>
        <dowloanpop :showcontent='showcontent' :tipstext='tipstext' @confirmclosepop='confirmclosepop'
            @closepop='closepop'></dowloanpop>
    </div>
</template>

<script src=""></script>
<script>
import {queryUserName} from "@/administration/history.js";
import historyTable from '@/components/history/table.vue';
import dowloanpop from '@/components/history/deletepop.vue';
import axios from 'axios';
import beasconfig from '@/api/beasconfig.js';
const userState = JSON.parse(sessionStorage.getItem("userState"));
export default {
  components: { 
    historyTable,
    dowloanpop
    },
    // props: {

    // },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    let curDate = (new Date()).getTime();
                    let three = 90 * 24 * 3600 * 1000;
                    let threeMonths = curDate - three;
                    return time.getTime() > Date.now() || time.getTime() < threeMonths;
                }
            },
            valueStart:'',  //开始时间
            user:[     //用户
            ],
            userId:'',   //用户id
            state:'',
            radiobroadcastData:[    //广播类型
                {
                    value:-1,
                    label:'请选择视频类型'
                },
                {
                    value:101,
                    label:'强拉视频'
                },
                {
                    value:200,
                    label:'视频呼叫'
                },
                {
                    value:201,
                    label:'视频回传'
                }
            ],
            radiobroadcastType:'',
            paging:{
                total:0,
                currentPage:1,
                pageNo:1,
                pageSize:20
            },
            userInfo:{}, //用户信息
            chooselist: [],     //选中数据
            tableData:[],    //table数据
            tableHead:[{     
                    lable:'发送人',
                    prop:'sender'
                    },
                    {     
                    lable:'接收人',
                    prop:'receiver'
                    },
                    {     
                    lable:'开始时间',
                    prop:'startTime'
                    },
                    {    
                    lable:'结束时间',
                    prop:'endTime'
                    },
                    {    
                    lable:'发送类型',
                    prop:'type'
                    }],       //表头数据
            bool:false,
            music:null,
            showcontent: false,
            tipstext: '确定下载所选的视频记录吗？',
            ossMap:{

            },
            timeNum: 0,
            temporaryTime: null,
            videoData:{
                dialogVisible:false,
                url:''
            },
            clientWidth:450,
            clientHeight:600,        //页面高度
        };
    },
    created() {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.getQueryUserName();
        this.getVideoRecord();
    },
    beforeCreate: function () { },
    mounted() {
        this.clientHeight = Math.round(document.documentElement.clientHeight - 350);
        this.clientWidth = Math.round(this.clientHeight * 0.75);
        //  this.$refs.myVideo.addEventListener("play", () => {
        //     console.log("video is playing");
        // });
        // // 监听视频暂停
        // this.$refs.myVideo.addEventListener("pause", () => {
        //     console.log("video is stop");
        // });
    },
    methods: {
         handleClose(){
            this.videoData.dialogVisible = false;
            this.$refs.myVideo.pause();
        },
        playbackVideo(row){        //回放视频
            if(this.$store.state.voiceCallInfo.callType != 0){
                    this.$MessageWarning('正在语音通话！');
                    return
            }
            if(this.$store.state.videoCallInfo.callType != 0){
                    this.$MessageWarning('正在视频通话！');
                    return
            }
            if(this.$store.state.userSpeech.isShow || this.$store.state.userSpeech.isBool){  //有人讲话或者自己讲话都不能查看回放

                return
            }
            this.videoData = {
                dialogVisible:true,
                url:this.getDownPath(row)
            }
            setTimeout(()=>{
                this.$refs.myVideo.play();
            },500)
        },
        getDownPath(row) {       //修改URL参数
            return `${beasconfig.getDownPath}/record/live/${row.userId}/${this.$moment(row.startTime).format("YYYY-MM-DD")}/${row.fileName}`;
        },
         getVideoRecord(){       //视频记录
                let that = this;
                let params = {
                    cid:that.userInfo.User_CompanyID,
                    page:that.paging.pageNo,
                    pageSize:that.paging.pageSize
                }
                 if(this.valueStart !='' && this.valueStart !=null){  //开始时间
                    params.startTime = this.$moment(this.valueStart[0]).valueOf();
                    params.endTime = this.$moment(this.valueStart[1]).valueOf();
                 } 
                 if(that.userId != ''){ //发送人
                        params.senderid = that.userId;
                }
                if(that.radiobroadcastType != '' && that.radiobroadcastType > 0){   //广播类型
                         params.type = that.radiobroadcastType;
                }
                axios.get(`${beasconfig.videoUrlapi}-${userState.server}/rtc/video/list`, { params }).then(res => {
                        if(res.data.code == 200){
                            that.paging.total = res.data.data.total;
                            let list = res.data.data.rows;
                            // console.log(list)
                            if(list.length > 0){
                                list.forEach(item => {
                                    item.startTime = this.$moment(item.startTime * 1000).format("YYYY-MM-DD HH:mm:ss");
                                    item.endTime = this.$moment(item.endTime * 1000).format("YYYY-MM-DD HH:mm:ss");
                                });
                                that.tableData = list;
                            }else{
                                that.tableData = [];
                            }
                        }else {
                            that.$MessageWarning(res.data.message);
                        }
                 })
        },
        getQueryUserName(){ //获取用户下拉
            let that = this;
            let data = {
                uId:that.userInfo.id
            }
            queryUserName(data).then(res=>{
                   if(res.data.code == 200){
                        that.user = res.data.data;
                        that.user.unshift({
                            User_ID:-1,
                            User_Name:'请选择发送人'
                        })
                    }else {
                        that.$MessageWarning(res.data.msg);
                    }
            })
        },
        search(){      //搜索
            this.paging.pageNo = 1;
            this.refresh();
        },
        download(row){ //下载
            var filePath = this.getDownPath(row);
            this.downLoadFile(filePath, row.fileName);
        },
        downLoadFile(url, name) {       //下载文件
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer'; // 返回类型blob
            xhr.onload = function() {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    let blob = this.response;
                    // 转换一个blob链接
                    // 注: URL.createObjectURL() 静态方法会创建一个 DOMString(DOMString 是一个UTF-16字符串)，
                    // 其中包含一个表示参数中给出的对象的URL。这个URL的生命周期和创建它的窗口中的document绑定
                    let downLoadUrl = window.URL.createObjectURL(new Blob([blob], {
                        type: 'video/mp4'
                    }));
                    // 视频的type是video/mp4，图片是image/jpeg
                    // 01.创建a标签
                    let a = document.createElement('a');
                    // 02.给a标签的属性download设定名称
                    a.download = name;
                    // 03.设置下载的文件名
                    a.href = downLoadUrl;
                    // 04.对a标签做一个隐藏处理
                    a.style.display = 'none';
                    // 05.向文档中添加a标签
                    document.body.appendChild(a);
                    // 06.启动点击事件
                    a.click();
                    // 07.下载完毕删除此标签
                    a.remove();
                };
            };
            xhr.send()
        },
        getSelectiondata(data) {        //选中的数据
             this.chooselist = data;
        },
        dowloan() {     //批量下载
                if(this.chooselist.length > 0){
                    this.showcontent = true;
                }else {
                    this.$MessageWarning('请选择要下载的视频记录！');
                }
        },
         closepop() {
            this.showcontent = false
        },
        confirmclosepop() {     //批量下载
             this.showcontent = false;
             this.tipstext = "确定下载所选的视频吗？";
             this.chooselist.forEach(item =>{
                    var filePath = this.getDownPath(item);;
                    this.downLoadFile(filePath,item.fileName);
             })
        },
        refresh(){      //刷新
              this.getVideoRecord();
        },
        comfir() {  //确定按钮
                this.refresh();
            },
        sizeChange(pageSize) {
                //当前页数据发生改变时
                this.paging.pageSize = pageSize;
                this.refresh();
            },
            prevClick(pageNum) {
                //前进按钮
                this.paging.pageNo = pageNum;
                this.refresh();
            },
            nextClick(pageNum) {
                //退后按钮
                this.paging.pageNo = pageNum;
                this.refresh();
            },
            currentChange(pageNum) {
                //当前页发生改变时
                this.paging.pageNo = pageNum;
                this.refresh();
            }
    },
    watch: {
        //监听事件
        radiobroadcastType(val) {
            // console.log(val == -1)
            if (val == -1) {
                this.radiobroadcastType = '';
            }
        },
        userId(val){
            if (val == -1) {
                this.userId = '';
            }
        },
        "$store.state.voiceCallInfo.callType"(type) {   //语音通话中~
            if(type > 0){
                this.handleClose();
            }
        },
        "$store.state.videoCallInfo.callType"(type) {   //视频通话中~
            if(type > 0){
                this.handleClose();
            }
        },
        "$store.state.userSpeech.isShow"(bool) {        //自己当前是否在讲话
            if(bool){
                this.handleClose();
            }
        },
        "$store.state.userSpeech.isBool"(bool) {        //是否有人在讲话
            if(bool){
                this.handleClose();
            }
        }
    },
};
</script>

<style scoped lang="less">
.box {
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: #ffffff;

    &_container {
        width: calc(100% - 20px);
        height: 100%;
        margin: auto;

        .head {
            width: 100%;
            padding: 10px 0px;
            background-color: #f0f0f0;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            font-size: 15px;
            color: #5a5655;

            &_container {
                flex: 1;
                margin-left: 10%;
            }

            &_dropDown {
                font-size: 15px;
                color: #5a5655;
                margin-right: 20px;

                span {
                    margin-right: 5px;
                }
            }

            &_select {
                width: 160px;
                height: 40px;
            }

            &_picker {
                width: 280px !important;
                text-align: center;
            }

            &_search {
                width: 70px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-color: #4d4d4d;
                background-size: 100% 100%;
                border-radius: 5px;
                color: #ffffff !important;
            }

            &_but {
                width: 110px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background-color: #327677;
                border-radius: 3px;
                color: #ffffff !important;
                margin-right: 5px;
            }
        }

        .table_one {
            height: calc(100vh - 73px - 145px);
        }

        // .table_tow {
        //     height: calc(100% - 235px);
        // }

        .table {
            width: 100%;
            background-color: #f0f0f0;
            overflow: auto;
            color: #000000;

            &_box {
                width: 100%;
                text-align: center;
            }

            &_but {
                color: #67c5cd;
                margin-right: 10px;
            }
        }

        .pagination {
            width: 100%;
            // height: 80px;
            text-align: left;
            position: relative;
            background-color: #ffffff;

            /deep/.el-pagination__jump {
                margin-left: 0;
            }

            .tip-sos {
                text-align: center;
                color: #b2b2b2;
                height: 30px;
                line-height: 30px;
            }

            &_bottom {
                padding: 15px 0px;
                // position: absolute;
                // bottom: 0px;
                // left: 0px;
            }

            .confirbtn {
                background-color: #5a5657;
                padding: 0 6px;
                color: #ffffff;
                margin-left: 4px;
                cursor: pointer;
                border-radius: 2px;
                // line-height: 14px;
            }
        }
    }
}

.video-box {
    width: 100%;
    height: 480px;
}

vidio {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bg_one {
    color: #ffffff;
    background: url("../../assets/history/select.png");
}

.bg_tow {
    color: #000000;
    background: url("../../assets/history/select1.png");
}

/deep/.el-dialog {
    position: relative;
    margin: 0 auto 50px;
    background: #5a5655;

    // width: 50%;
    .el-dialog__title {
        color: #ffffff;
        font-size: 15px;
    }

    .el-dialog__body {
        padding: 5px 5px;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: #4aFFFF;
    }

    .el-dialog__header {
        padding: 20px 20px 5px;
    }
}

.flex {
    display: flex;
    align-items: center;
}
</style>